import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-common-confirm-modal',
  standalone: true,
  imports: [NgbModalModule,CommonModule],
  templateUrl: './common-confirm-modal.component.html',
  styleUrl: './common-confirm-modal.component.scss',
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class CommonConfirmModalComponent {
  // @ViewChild('confirmModal') confirmModal!: TemplateRef<any>;
  // modalRef!:NgbModalRef
  // @Input() set isModalOpen(value:any){
  //   if (value) {
  //     this.openModal()
  //   }
  // }
  @Input() type:any
  @Input() modalType:any
  // @Output() closeConfirmModal = new EventEmitter();
  constructor(public activeModal: NgbActiveModal){

  }

  // openModal(){
  //   this.modalRef = this.activeModal.open(this.confirmModal, { centered: true });

  //   this.modalRef.result.then(
  //     () => {
  //       this.closeModal(false);
  //     },
  //     () => {
  //       this.closeModal(false);
  //     }
  //   );
  // }



}
