
<!-- <ng-template #confirmModal let-modal> -->
    <div class="modal-header d-flex justify-content-between">
        <h4
          class="modal-title text-dark fw-medium"
          id="modal-basic-title"
        >
          Delete {{modalType}}
        </h4>
        <button
          class="btn btn-sm btn-light"
          (click)="activeModal.close(false)"
        >
          <i class="bi bi-x text-primary fs-4"></i>
        </button>
      </div>
      
      <div class="modal-body">
        <div>
          <p>
            Are you sure you want to delete the
            <strong>{{ type }}</strong
            >?
          </p>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <button
          type="button"
          class="btn btn-primary"
          (click)="activeModal.close(true)"
        >
          Confirm
        </button>
        <button
          type="button"
          class="btn btn-light"
          (click)="activeModal.close(false)"
        >
          Cancel
        </button>
      </div>      
<!-- </ng-template> -->