import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  NgbModal,
  NgbModalModule,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-common-modal',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgbModalModule],
  templateUrl: './common-modal.component.html',
  styleUrl: './common-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonModalComponent {
  @ViewChild('createModal') createModal!: TemplateRef<any>;
  modalRef!: NgbModalRef;
  @Input() set isModalOpen(value: any) {
    if (value) {
      this.openModal();
    }
  }
  @Input() type: any;
  @Input() set actionType(value: any) {
    this.actionVal = value?.actionType;
    if (value?.actionType == 'Edit') {
      this.createForm.patchValue({ name: value?.actionValue });
    } else {
      this.createForm.reset();
    }
    this.cdr.markForCheck()
  }
  @Input() value: any;
  @Output() submitCreateModal = new EventEmitter();

  createForm: any = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });
  isSubmitted: any = false;
  actionVal: any;

  constructor(public activeModal: NgbModal, private cdr: ChangeDetectorRef) {}

  get fc() {
    return this.createForm.controls;
  }

  openModal() {
    this.isSubmitted = false;
    this.modalRef = this.activeModal.open(this.createModal, { centered: true });
    this.modalRef.result.then(
      () => {
        // console.log(`value :>> `, value);
        // this.closeModal();
      },
      () => {
        // console.log(`value2 :>> `, value2);
        this.closeModal();
      },
    );
  }

  closeModal() {
    this.submitCreateModal.emit('');
    this.cdr.markForCheck();
    this.modalRef.close();
  }

  submitForm() {
    this.isSubmitted = true;
    if (this.createForm.valid) {
      this.submitCreateModal.emit(this.createForm.get('name')?.value || '');
      this.isSubmitted=false
      this.createForm.reset();
      this.cdr.markForCheck();
      this.modalRef.close();
    }
  }
}
