
<ng-template #createModal let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h4
          class="modal-title text-dark fw-medium"
          id="modal-basic-title"
        >
            {{actionVal}} {{type}}
        </h4>
        <button
          class="btn btn-sm btn-light"
          (click)="modalRef.dismiss()"
        >
          <i class="bi bi-x text-primary fs-4 "></i>
        </button>
      </div>
      <form [formGroup]="createForm">
      <div class="modal-body">
        
          <div class="col-md-12">
            <div class="form-group">
              <label
                for="name"
                class="form-label"
                >Name</label
              >
              <input
                type="text"
                class="form-control"
                id="name"
                formControlName="name"
                placeholder="Enter Name"
                maxlength="12"
                [ngClass]="{
                  'border-danger':
                    (fc['name'].touched ||
                      fc['name'].dirty ||
                      isSubmitted) &&
                    fc['name'].invalid
                }"
              />
              @if ( (fc['name'].touched || fc['name'].dirty
              || isSubmitted) && fc['name'].invalid) {
              <div>
                @if (fc['name'].errors?.['required']) {
                <div class="text-danger">Name is required.</div>
                }
              </div>
              }
            </div>
          </div>
      
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <button
          type="submit"
          class="btn btn-primary"
          (click)="submitForm()"
        >
          Save
        </button>
      </div>
      </form>    
  </ng-template>

  